.main {
  display: flex;
  height: 100vh;
  flex-flow: column;
  background-color: gray;
  /* position: relative; */
}

.detectedDiv {
  height: 40%;
  padding: 5px;
  word-wrap: break-word;
  border: solid gray 5px;
  white-space: pre;
  overflow: auto;
  font-family: monospace;
  text-rendering: auto;
  color: fieldtext;
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  appearance: auto;
  -webkit-rtl-ordering: logical;
  resize: vertical;
  cursor: text;
  background-color: field;
  column-count: initial !important;
  writing-mode: horizontal-tb !important;
  /* box-sizing: border-box; */
  margin: 0em;
  /* border-width: 1px; */
  /* border-style: solid;/ */
  /* border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133)); */
  /* border-image: initial; */
  padding: 5px;
}

.logContent {
  scroll-behavior: smooth;
  border: solid gray 5px;
  width: 100%;
  height: 60%;
  word-wrap: break-word;
  white-space: pre;
  overflow: auto;
  font-family: monospace;
  text-rendering: auto;
  color: fieldtext;
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  appearance: auto;
  -webkit-rtl-ordering: logical;
  resize: vertical;
  cursor: text;
  background-color: field;
  column-count: initial !important;
  writing-mode: horizontal-tb !important;
  box-sizing: border-box;
  margin: 0em;
  /* border-width: 1px; */
  /* border-style: solid; */
  /* border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133)); */
  /* border-image: initial; */
  padding: 5px;
}

.itemDetected {
  background-color: rgb(240, 155, 155);
  cursor: pointer;
}

.itemDetected:hover {
  background-color: rgba(240, 155, 155, 0.583);
}
.lineNumberLog {
  background-color: yellow;
}

.buttonOption {
  /* position: "sticky"; */
  /* top: 0; */
  /* background-color: "white"; */
  text-align: "center";
  background-color: rgba(255, 0, 0, 0);
  display: "flex";
  padding: 0 10px 0 0;
  color: white;
  font-weight: 700;
  border-left: 5px solid gray;
  border-right: 5px solid gray;
}

.buttonOption span{
    float: right;
}