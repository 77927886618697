#bodyScreen {
    height: 100%;
    padding: 5px;
    word-wrap: break-word;
    border: solid gray 5px;
    white-space: pre;
    overflow: auto;
    font-family: monospace;
    text-rendering: auto;
    color: fieldtext;
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    appearance: auto;
    -webkit-rtl-ordering: logical;
    resize: vertical;
    cursor: text;
    background-color: field;
    column-count: initial !important;
    writing-mode: horizontal-tb !important;
    /* box-sizing: border-box; */
    margin: 0em;
    /* border-width: 1px; */
    /* border-style: solid;/ */
    /* border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133)); */
    /* border-image: initial; */
    padding: 5px;
    font-size: 1.2rem;
  }